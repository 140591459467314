import { useLocation, useNavigate, useParams } from "react-router-dom";
import FormLoader from "../../Components/Common/FormLoader"
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { GetData, PostData, PostDataWithToken } from "../../ApiHelper/ApiHelper";
import { toast } from "react-toastify";
import moment from "moment";

export default function CustomerReassignInstaller() {
  const navigate = useNavigate();
  const location = useLocation();
  const [EnquiryDetials, setEnquiryDetials] = useState({});
  const [scheduleMessage, setScheduleMessage] = useState("");
  const [SelectedValue, setSelectedValue] = useState("");
  console.log("loooo...", location);
  const [timeSlotValue, setTimeSlotValue] = useState();
  const [AllTimeSlot, setAllTimeSlot] = useState([]);
  const [formStates, setFormStates] = useState({
    loader: true,
    showForm: false,
  });
  const [selectedInstaller, setSelectedInstaller] = useState({
    name: "",
    id: "",
  })


  const { url } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const timeSlotChangeHandler = (e) => {
    setTimeSlotValue(e.target.value);
    // setCallApi2(true);
  };

  const watchAllFields = watch()

  useEffect(() => {
    GetData(`sales/get-enquiry/${url}`).then(
      (response) => {
        updateStateHandler("loader", false);
        if (response.status === true) {
          updateStateHandler("showForm", true);
          setEnquiryDetials(response?.data);
          const submitData = {
            categories: response?.data?.category,
            storeId: response?.data?.outlet?.id,
          }
          setSelectedInstaller({
            name: response?.data?.installer_tasks?.[response?.data?.installer_tasks?.length - 1]?.installer?.firstName,
            id: response?.data?.installer_tasks?.[response?.data?.installer_tasks?.length - 1]?.installer?.id,
          })

        }
      }
    );
  }, [])

  const updateStateHandler = (key, value) => {
    setFormStates((prev) => ({
      ...prev,
      [key]: value
    }));
  }

  // const onSubmit = (data) => {

  //   const submitData = {
  //     schedulePersonId: AssignedPerson?.id,
  //     enquiryId: url,
  //     ScheduleId: timeSlotValue,
  //     date: data?.date,
  //   };
  //   console.log("submit dataaaa...", submitData);
  //   PostData(`auth/update-schedule`, submitData).then(response => {
  //     if (response.status === true) {
  //       toast.success("Measurer reassigned successfully");
  //       navigate("/thank-you")
  //     } else {
  //       toast.error(response.data.message);
  //     }
  //   })
  // }
  const dateChangeHandler = (event) => {
    let date = moment(event?.target?.value).format('YYYY-MM-DD');
    let usedCategory = "";
    const fabricCategory = ["Curtains", "sheers", "Rods/tracks", "Blinds"];
    if (EnquiryDetials?.category.every(category => fabricCategory.includes(category))) {
      usedCategory = "curtain";
    }
    GetInstallerSchedule(date, selectedInstaller?.id, usedCategory)
  }

  const GetInstallerSchedule = (date, installer, category) => {
    GetData(`installer/unassign-installer-slot?date=${date}&userid=${installer}${category ? `&category=${category}` : ""}`).then(response => {
      setAllTimeSlot(response.data?.schedules);
      if (response.data?.schedules?.length === 0) {
        setScheduleMessage("Sorry no schedules on Sunday");
      } else {
        setScheduleMessage("");
      }
    })
  }

  const CreateNewOutlet = (data) => {
    // let date;
    // date = isreassign === "measurement" ? { postpone_date: moment(data?.postpone_date).format("DD-MM-YYYY") } : { postponeDate: moment(data?.postponeDate).format("DD-MM-YYYY"), };
    let arrayIndex = EnquiryDetials?.installer_tasks?.length - 1;
    const val = {
      id: EnquiryDetials?.installer_tasks?.[arrayIndex]?.id,
      status: "postponed",
      remark: SelectedValue?.target?.value,
      postponeDate: moment(data?.postpone_date).format("YYYY-MM-DD"),
    };

    PostData("installer/update-schedule", val).then((response) => {
      if (response.status === true) {
        console.log("first", response.message);
        toast.success(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        navigate("/thank-you");
        // toggle();
      } else {
        toast.error(response.data.message, {
          position: "top-right",
        });
      }
    });
  };

  return (<div className="authincation h-100">
    <div className="container h-100vh">
      <div className="row justify-content-center h-100 align-items-center">
        <div className="col-xl-6">
          {formStates?.loader ? <FormLoader /> :
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  {!formStates?.showForm ?
                    <div class="card-header d-flex justify-content-center">
                      <h4>Access Denied !</h4>
                    </div> : <>
                      <div className="card-header">
                        <h4>Re-assign Installer</h4>

                        {/* <span onClick={() => { navigate(-1) }} style={{ cursor: "pointer" }}><i className="fas fa-arrow-left" style={{ fontSize: "20px" }}></i></span> */}
                      </div>
                      <div className="container-fluid">
                        <div className="row flex-item-center">
                          <div className="col-xl-12">
                            <div class="basic-form">
                              <form onSubmit={handleSubmit(CreateNewOutlet)}>
                                <div className="mb-3 row align-items-center">
                                  <div className="col-lg-12 my-1">
                                    <label className="me-sm-2">
                                      Select Reason for Postponing Installer
                                    </label>
                                    <select
                                      className="me-sm-2  form-control"
                                      onChange={(e) => {
                                        setSelectedValue(e);
                                      }}
                                      required
                                    >
                                      <option value={""} selected>Choose...</option>
                                      <option value={"No one is available at site"}>  No one is available at site</option>
                                      <option value={"  Site is not Ready"}>
                                        Site is not Ready
                                      </option>
                                    </select>
                                  </div>
                                  <div>
                                    <label className="col-sm-12 col-form-label" htmlFor="fnf2">
                                      Date
                                    </label>
                                    <div className="col-sm-12">
                                      <input
                                        {...register("postpone_date", {
                                          required: "please enter date",
                                          onChange: dateChangeHandler
                                        })}
                                        type="date"
                                        className="form-control"
                                        id="fnf2"
                                        placeholder="outlet Password"
                                        // readOnly="true"
                                        // value={lastId}
                                        min={moment().add(1, "days").format("YYYY-MM-DD")}
                                        required
                                      // minDate={new Date()}
                                      />
                                      <span className="text-danger">
                                        {errors.postpone_date && errors.postpone_date.message}
                                      </span>
                                    </div>
                                  </div>
                                  <div class="col-lg-12 my-1">
                                    <label class="me-sm-2">Select Time Slot For Your Installer</label>
                                    <select class="me-sm-2  form-control"
                                      onChange={timeSlotChangeHandler}
                                      required
                                    >
                                      <option value="">Choose...</option>
                                      {AllTimeSlot?.map((data) => <option value={data?.id}>{data?.start_time}-{data?.end_time}</option>)}
                                    </select>
                                  </div>
                                  <p className="text-danger">{scheduleMessage}</p>
                                </div>
                                <div className="form-buttons text-center mb-2">
                                  {/* <button onClick={() => toggle()} className="btn btn-secondary me-3">
                                    Cancel
                                  </button> */}

                                  <button className="btn btn-primary">Submit</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>}

                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>)
}