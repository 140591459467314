import { useEffect, useState } from "react";
// import { GetData, GetDataWithToken, PostData } from "../../ApiHelper/ApiHelper";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetData, GetDataWithToken, PostData, PostDataWithToken } from "../../ApiHelper/ApiHelper";
import FormLoader from "../../Components/Common/FormLoader";
import moment from "moment";

const CustomerAssignMeasurer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [scheduleList, setScheduleList] = useState();
  const [callApi, setCallApi] = useState(true);
  const [callApi2, setCallApi2] = useState(false);
  const [timeSlotValue, setTimeSlotValue] = useState();
  const [measurerList, setMeasurerList] = useState([]);
  const [AssignedPerson, setAssignedPerson] = useState({});
  const [loading, setLoading] = useState(false);
  const [EnquiryDetials, setEnquiryDetials] = useState({});
  console.log("loooo...", location);
  const [formStates, setFormStates] = useState({
    loader: true,
    showForm: false,
  });

  const timeSlotChangeHandler = (e) => {
    setTimeSlotValue(e.target.value);
    setCallApi2(true);
  };

  const { url } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const watchAllFields = watch()

  useEffect(() => {
    GetData(`sales/get-enquiry/${url}`).then(
      (response) => {
        updateStateHandler("loader", false);
        if (response.status === true) {
          if (response?.data?.status == "fresh") {
            updateStateHandler("showForm", true);
          }
          setEnquiryDetials(response?.data);
          const submitData = {
            categories: response?.data?.category,
            storeId: response?.data?.outlet?.id,
          }
          PostDataWithToken(
            `superadmin/get-unassigned-user`,
            submitData
          ).then((response) => {
            if (response.status === true) {
              // setCallApi2(false);
              setAssignedPerson({
                name: `${response?.data?.[0]?.firstName} ${response?.data?.[0]?.lastName}`,
                id: response?.data?.[0]?.id
              })
              // setAllUnAssignedUser(response.data);
            } else {
              // setCallApi2(false);
            }
          });
        }
      }
    );
  }, [])



  useEffect(() => {
    let userId;
    // if (EnquiryDetials.outlet?.email === "ffsl@fandf.in") {
    //   EnquiryDetials?.category?.includes("Wallpaper") ?
    //     userId = 41 : userId = 64
    // } else if (EnquiryDetials.outlet?.email === "smpc@fandf.in" ||
    //   EnquiryDetials.outlet?.email === "smldh@fandf.in"
    // ) {
    //   EnquiryDetials?.category?.includes("Wallpaper") ?
    //     userId = 126 : userId = 126
    // } else {
    //   EnquiryDetials?.category?.includes("Wallpaper") ?
    //     userId = 61 : userId = 58
    // }

    // EnquiryDetials.outlet?.email === "ffsl@fandf.in"|| ?
    //   userId = "22" : ""
    //   ;
    if (watchAllFields?.date) {
      GetData(`superAdmin/get-unassigned-timeslot/${AssignedPerson?.id}?date=${watchAllFields?.date}`).then((response) => {
        if (response.status == true) {
          let newSchedules = [];
          if (moment(watchAllFields?.date).format('DD/MM/YYYY') == moment().format('DD/MM/YYYY')) {
            for (let i = 0; i < response?.data?.schedules?.length; i++) {
              let startTime = response?.data?.schedules?.[i]?.start_time;
              let startTimeMoment = moment(startTime, "HH:mm:ss");
              let currentTime = new Date();
              let currentHour = currentTime.getHours();
              let currentMinute = currentTime.getMinutes();
              // Create a moment for the current time using the current hour and minute
              let currentTimeMoment = moment(`${currentHour}:${currentMinute}:00`, "HH:mm:ss");
              // Calculate the difference in duration between current time and start time
              let duration = moment.duration(startTimeMoment.diff(currentTimeMoment));
              // Convert the duration to minutes
              let minutes = duration.asMinutes();
              // Log the result in minutes
              if (+minutes > 40) {
                newSchedules.push(response?.data?.schedules?.[i]);
              }
              console.log(`Start Time: ${startTime}, Difference: ${minutes} minutes`);
            }
            setScheduleList(newSchedules);
          }
          else {
            setScheduleList(response.data?.schedules);
          }
        }
      })
    }
  }, [watchAllFields?.date])

  const updateStateHandler = (key, value) => {
    setFormStates((prev) => ({
      ...prev,
      [key]: value
    }));
  }

  const onSubmit = (data) => {

    const submitData = {
      schedulePersonId: AssignedPerson?.id,
      enquiryId: url,
      ScheduleId: timeSlotValue,
      date: moment(data?.date).format("YYYY-MM-DD"),
      // categories: EnquiryDetials?.category,
      // storeId: EnquiryDetials?.outlet?.id,
      // ScheduleId: timeSlotValue,
      // date: data?.date,
      // enquiryId: location.state.EnquiryDetials.id,
      // schedulePersonId: data?.measurer
    };
    console.log("submit dataaaa...", submitData);
    PostData(`auth/assign-schedule-enquiry`, submitData).then(response => {
      if (response.status === true) {
        toast.success("Measurer assigned successfully");
        navigate("/thank-you")
      } else {
        toast.error(response.data.message);
      }
    })

  }

  // useEffect(() => {
  //   if (callApi) {
  //     GetData("superadmin/get-schedule?type=measurer").then(response => {
  //       setScheduleList(response.data);
  //       setCallApi(false)
  //     })
  //   }
  //   if (callApi2) {
  //     GetData(`superadmin/get-unassigned-user/${timeSlotValue}`).then(response => {
  //       setMeasurerList(response.data);
  //       setCallApi2(false);
  //     })
  //   }
  // }, [callApi, callApi2])

  return (
    <div className="authincation h-100">
      <div className="container h-100vh">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-xl-6">
            {formStates?.loader ? <FormLoader /> :
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    {!formStates?.showForm ?
                      <div class="card-header d-flex justify-content-center">
                        <h4>Access Denied !</h4>
                      </div> : <>
                        <div className="card-header">
                          <h4>Schedule a task</h4>

                          <span onClick={() => { navigate(-1) }} style={{ cursor: "pointer" }}><i className="fas fa-arrow-left" style={{ fontSize: "20px" }}></i></span>
                        </div>
                        <div className="container-fluid">
                          <div className="row flex-item-center">
                            <div className="col-xl-12">
                              <div class="basic-form">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <div class="row align-items-center">

                                    <div class="col-lg-12 my-1">
                                      <label class="me-sm-2">Select Date For Measurement</label>
                                      <input type="date"
                                        min={new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]}
                                        class="form-control" name="date"
                                        {...register(`date`, {
                                          required: true,
                                          maxLength: 80,
                                        })}
                                      />
                                    </div>

                                    <div class="col-lg-12 my-1">
                                      <label class="me-sm-2">Select Time Slot For Your measurer</label>
                                      <select
                                        class="me-sm-2  form-control" onChange={timeSlotChangeHandler}
                                        required
                                      >
                                        <option value={""}>Choose...</option>
                                        {scheduleList?.map((data) => <option value={data?.id}>{data?.start_time}-{data?.end_time}</option>)}
                                      </select>
                                    </div>

                                  </div>
                                  <button class="btn btn-primary my-4" type="submit">submit</button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>}

                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default CustomerAssignMeasurer;